:root {
    --fs-project-hover: hotpink;
    --fs-project-background: black;
    --fs-hover-text: white;
    --fs-projects: #000000;
}

#fs-project {
    background: rgb(34, 34, 34);
    margin: 0;
    padding: 0;
    padding-bottom: 5rem;
}

#fs-project h1 {
    font-size: 4rem;
    font-family: 'Allerta Stencil', sans-serif;
    border: solid 2px white;
    background-color: transparent;
}

.fs-portfolio {
    color: var(--fs-hover-text);
    text-transform: none;
}

.fs-projects {
    border: .3rem solid white;
    background-color: var(--fs-projects);
    border-radius: 1rem;
    padding: 1rem;
}

.fs-project-title {
    text-decoration: underline;
    text-underline-offset: .3rem;
}

.img-fluid-fs {
    min-height: 25rem;
    max-width: 100%;
    height: auto;
    border-radius: 1rem;
}

.fs-project-item h3 {
    font-family: "Preahvihear", sans-serif;
}

.fs-project-description {
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
    margin: 0 auto;
    font-size: 1.5rem;
}

.fs-project-item h4 {
    font-size: 1rem;
    font-family: "Preahvihear", sans-serif;
}

.fs-project-links {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
}

.fs-github-btn,
.fs-live-btn {
    background-color: var(--fs-project-hover);
    padding: 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    color: var(--fs-project-background);
    font-weight: bold;
}

.fs-github-btn:hover {
    background-color: var(--fs-project-background);
    color: var(--fs-hover-text);
    transform: scale(1.1);
}

.fs-live-btn:hover {
    background-color: var(--fs-project-background);
    color: var(--fs-hover-text);
    transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
    .img-fluid-fs {
        min-height: 20rem;
    }
}

@media screen and (max-width: 768px) {
    .img-fluid-fs {
        min-height: 15rem;
    }

    .fs-project-description {
        font-size: 1rem;
    }
}

@media screen and (max-width: 430px) {
    #fs-project h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 375px) {
    .img-fluid-fs {
        min-height: 10rem;
    }
}

@media screen and (max-width: 320px) {
    .fs-project-description {
        font-size: .8rem;
    }
}