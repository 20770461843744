:root {
  --about-text: black;
  --about-bg: rgb(194, 194, 194);
  --about-header: hotpink;
}

#about-me {
  width: 100vw;
  min-height: 100vh;
  background: rgb(0, 0, 0);
}

#about-me h1 {
  font-size: 4rem;
  font-family: 'Allerta Stencil', sans-serif;
  text-transform: none;
  border: solid 2px white;
  background-color: transparent;
}

.about-me-container {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 5rem;
  padding-bottom: 5rem;
}

.about-me-image {
  background-image: url("../../assets/me-and-boys.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 90%;
  height: 50rem;
  border: 3px solid rgb(194, 194, 194);
  border-radius: 2rem;
  overflow: hidden;
}

.about-me-content {
  font-size: 1.5rem;
  background-color: var(--about-bg);
  padding: .5rem;
  border: 1rem double white;
  border-radius: 1rem;
  opacity: .9;
}

.about-me-content p {
  font-family: 'Playpen Sans', cursive;
}

@media screen and (max-width: 1024px) {
  .about-me-container {
    grid-template-columns: 1fr;
  }

  .about-me {
    width: 100%;
    margin: auto;
  }

  .about-me-image {
    width: 100%;
    margin-bottom: 2rem;
  }

  .about-me-content p {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .about-me-image {
    height: 40rem;
  }
}

@media screen and (max-width: 600px) {
  #about-me {
    margin-left: -1rem;
    padding-bottom: 40rem;
  }

  .about-me-container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about-me {
    width: 80%;
  }

  .about-me-image {
    height: 40rem;
  }

  .about-me-content {
    text-align: center;
    width: 100%;
  }

  .about-me-content p {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
  }
}

@media screen and (max-width: 540px) {
  #about-me {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 430px) {
  #about-me {
    margin: 0;
  }
}

@media screen and (max-width: 425px) {
  #about-me {
    margin: 0;
    padding: 0;
  }

  #about-me h1 {
    font-size: 3rem;
  }

  .about-me-image {
    height: 30rem;
  }

  .about-me-content {
    width: 80vw;
    margin: auto;
    font-size: 1.3rem;
  }

  .about-me-content p {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 375px) {
  .about-me-image {
    height: 25rem;
  }
}

@media screen and (max-width: 320px) {
  .about-me-image {
    height: 25rem;
  }

  .about-me-content p {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 280px) {
  .about-me-content {
    font-size: 1rem;
  }
}