:root {
    --back-end-skills: black;
    --back-end-title: white;
}

#back-end-skills {
    font-family: "Preahvihear", sans-serif;
    margin-top: 1rem;
}

.back-end-section-title {
    font-size: 1rem;
    text-underline-offset: .5rem;
    color: var(--back-end-title);
}

.back-end-skill-icon {
    width: 50px;
    height: 50px;
}

.back-end-skills-icons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding-top: 1rem;
    align-items: center;
    text-align: center;
    color: var(--back-end-title);
}


@media screen and (max-width: 1440px) {
    .back-end-skills-icons {
        gap: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .back-end-skills-section {
        columns: 2;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .back-end-skills-icons {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 425px) {
    .back-end-section-title h3 {
        font-size: 1rem;
        padding-left: 1rem;
    }
}

@media screen and (max-width: 375px) {
    .back-end-section-title h3 {
        font-size: .9rem;
    }
}