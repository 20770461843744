:root {
    --logo-background: rgb(16, 18, 18);
    --logo-title: hotpink;
}

#logo {
    background-color: var(--logo-background);
}

.logo-title {
    border: solid 2px white;
    background-color: transparent;
    text-transform: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Allerta Stencil', sans-serif;
    margin-bottom: 2rem;
}

.logo-projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    justify-content: center;
    gap: 5rem;
    padding-bottom: 2rem;
}

.ddclogo,
.initsplace {
    width: 40rem;
    height: 60rem;
    border-radius: 2rem;
}

.nordic-fjord-photo {
    width: 40rem;
    height: 30rem;
    border-radius: 2rem;
}

.pandalogo,
.the-roasted-bean,
.sven-the-fjord,
.flint-and-flame,
.snowdrop,
.onward,
.pitch,
.pug-icon,
.rocketlogo,
.balloonlogo {
    width: 40rem;
    height: auto;
    border-radius: 2rem;
}

@media screen and (max-width: 1280px) {
    .ddclogo,
    .initsplace {
        width: 35rem;
        height: 50rem;
    }

    .nordic-fjord-photo {
        height: 26rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 35rem;
    }
}

@media screen and (max-width: 1024px) {
    .ddclogo,
    .initsplace {
        width: 25rem;
        height: 35rem;
    }

    .nordic-fjord-photo {
        height: 19rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 25rem;
    }
}

@media screen and (max-width: 820px) {
    .ddclogo,
    .initsplace {
        width: 22rem;
        height: 30rem;
    }

    .nordic-fjord-photo {
        height: 17rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 22rem;
    }
}

@media screen and (max-width: 768px) {
    .ddclogo,
    .initsplace {
        width: 20rem;
        height: 30rem;
    }

    .nordic-fjord-photo {
        height: 15rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 20rem;
    }
}

@media screen and (max-width: 540px) {
    .ddclogo,
    .initsplace {
        width: 14rem;
        height: 20rem;
    }

    .nordic-fjord-photo {
        height: 11rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 14rem;
        margin: auto;
    }
}

@media screen and (max-width: 430px) {
    .logo-projects {
        display: flex;
        flex-direction: column;
    }

    .ddclogo,
    .initsplace {
        width: 25rem;
        height: 35rem;
    }

    .nordic-fjord-photo {
        height: 18rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 25rem;
    }
}

@media screen and (max-width: 414px) {
    .ddclogo,
    .initsplace {
        width: 23rem;
        height: 32rem;
        margin: auto;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 23rem;
        margin: auto;
    }
}

@media screen and (max-width: 375px) {
    .ddclogo,
    .initsplace {
        width: 20rem;
        height: 27rem;
    }

    .nordic-fjord-photo {
        height: 15rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 20rem;
        margin: auto;
    }
}

@media screen and (max-width: 320px) {
    .ddclogo,
    .initsplace {
        width: 17rem;
        height: 25rem;
    }

    .nordic-fjord-photo {
        height: 13rem;
    }

    .pandalogo,
    .nordic-fjord-photo,
    .the-roasted-bean,
    .sven-the-fjord,
    .flint-and-flame,
    .snowdrop,
    .onward,
    .pitch,
    .pug-icon,
    .rocketlogo,
    .balloonlogo {
        width: 17rem;
    }
}