:root {
    --graphic-design-skills: rgb(0, 0, 0);
    --graphic-design-title: white;
}

#graphic-design-skills {
    font-family: "Preahvihear", sans-serif;
    margin-top: 1rem;
}

.graphic-design-skills-icons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    text-align: center;
    color: var(--graphic-design-title);
    padding-top: 1rem;
}

.graphic-design-skill-icon {
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 1440px) {
    .graphic-design-skills-icons {
        gap: 1rem;
    }
}

@media screen and (max-width: 912px) {
    .graphic-design-skills-section {
        columns: 2;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .graphic-design-skills-icons {
        display: flex;
        flex-direction: column;
    }
}