:root {
  --be-project-hover: hotpink;
  --be-project-background: black;
  --be-hover-text: white;
  --be-projects: #000000;
}

#be-project {
  background: rgb(34, 34, 34);
  margin: 0;
  padding: 0;
  padding-bottom: 5rem;
}

#be-project h1 {
  font-size: 4rem;
  font-family: 'Allerta Stencil', sans-serif;
  border: solid 2px white;
  background-color: transparent;
}

.be-portfolio {
  color: var(--be-hover-text);
  text-transform: none;
}

.be-projects {
  border: .3rem solid white;
  background-color: var(--be-projects);
  border-radius: 1rem;
  padding: 1rem;
}

.be-project-title {
  text-decoration: underline;
  text-underline-offset: .3rem;
}

.img-fluid-be {
  min-height: 25rem;
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
}

.be-project-item h3 {
  font-family: "Preahvihear", sans-serif;
}

.be-project-description {
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  margin: 0 auto;
  font-size: 1.5rem;
}

.be-project-item h4 {
  font-size: 1rem;
  font-family: "Preahvihear", sans-serif;
}

.be-project-links {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.be-github-btn,
.be-live-btn {
  background-color: var(--be-project-hover);
  padding: 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  color: var(--be-project-background);
  font-weight: bold;
}

.be-github-btn:hover {
  background-color: var(--be-project-background);
  color: var(--be-hover-text);
  transform: scale(1.1);
}

.be-live-btn:hover {
  background-color: var(--be-project-background);
  color: var(--be-hover-text);
  transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
  .img-fluid-be {
      min-height: 20rem;
  }
}

@media screen and (max-width: 768px) {
  .img-fluid-be {
      min-height: 15rem;
  }

  .be-project-description {
      font-size: 1rem;
  }
}

@media screen and (max-width: 430px) {
  #be-project h1 {
      font-size: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .img-fluid-be {
      min-height: 10rem;
  }
}

@media screen and (max-width: 320px) {
  .be-project-description {
      font-size: .8rem;
  }
}