:root {
    --web-design-background: rgb(16, 18, 18);
}

#web-design-projects {
    background-color: var(--web-design-background);
    height: 100%;
}

.figma-title {
    border-bottom: 1px solid white;
}

.tiktok-figma,
.landing-page,
.sign-up,
.credit-card-form {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.web-design-container {
    padding-bottom: 5rem;
}

.web-design-projects-title {
    border: solid 2px white;
    background-color: transparent;
    text-transform: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Allerta Stencil', sans-serif;
}

@media screen and (max-width: 1024px) {
    .web-design-container {
        margin: auto;
        max-width: 950px;
    }
}