:root {
  --name: rgb(255, 255, 255);
}

#header {
  padding-top: 3rem;
  background-image: url(../../assets/sven-head-left.webp);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  margin-top: -5rem;
  padding-top: 5rem;
  padding-bottom: 15rem;
}

.my-desc {
  font-family: 'Playpen Sans', cursive;
  font-size: 2rem;
  border: 5px solid rgb(255, 255, 255);
  background-color: rgba(85, 85, 85, 0.7);
  padding: .5rem;
  color: var(--name);
  text-align: center;
}

.hello {
  font-family: 'Playpen Sans', cursive;
  font-size: 3rem;
  color: var(--name);
  text-align: end;
}

.header-container h1 {
  font-family: 'Playpen Sans', cursive;
  color: var(--name);
  background-color: transparent;
  text-transform: none;
  font-size: 5rem;
  text-align: end;
}

.social-media ul {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-top: 6rem;
}

.social-media ul li {
  position: relative;
  list-style: none;
  margin: 0 20px;
  cursor: pointer;
}

.social-media ul li a {
  text-decoration: none;
}

.social-media ul li a .fab,
.social-media ul li a .fa-brands {
  font-size: 6em;
  color: #8e8e8e;
}

.social-media ul li a::before {
  font-family: "FontAwesome";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 6em;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}

.social-media ul li:nth-child(1) a::before {
  content: "\f0e1";
  background-image: linear-gradient(180deg,
      #ffffff 0%, #006fee 25%, #0011ff 75%,
      #06007e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 4px solid #0011ff;
}

.social-media ul li:nth-child(2) a::before {
  content: "\f09a";
  background-image: linear-gradient(180deg, #17A9FD 0%, #0165E1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 4px solid #17A9FD;
}

.social-media ul li:nth-child(3) a::before {
  content: "\f09b";
  background-image: linear-gradient(180deg, #000000 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 4px solid #ffffff;
}

.social-media ul li:hover a::before {
  height: 100%;
}

.fa,
.fa-brands,
.fa-classic,
.fa-regular,
.fa-sharp,
.fa-solid,
.fab,
.far,
.fas {
  line-height: 1.5 !important;
}

@media screen and (max-width: 1024px) {
  #header {
    background-position: -13rem;
  }

  .hello {
    font-size: 2rem;
  }

  .header-container h1 {
    font-size: 3.5rem;
  }

  .social-media ul {
    padding-top: 1.5rem;
  }
}

@media screen and (max-width: 912px) {
  .header-container h1 {
    font-size: 4rem;
  }

  .social-media ul {
    margin-top: 11rem;
    margin-left: -3rem;
    justify-content: flex-start;
    position: absolute;
    flex-direction: column;
  }

  .social-media ul li a .fab,
  .social-media ul li a .fa-brands {
    font-size: 6rem;
    color: #000000;
  }

  .social-media ul li a::before {
    font-size: 6rem;
  }
}

@media screen and (max-width: 820px) {
  .social-media ul {
    margin-top: 7rem;
    margin-left: -3rem;
  }

  .social-media ul li a .fab,
  .social-media ul li a .fa-brands {
    font-size: 4rem;
  }

  .social-media ul li a::before {
    font-size: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .social-media ul {
    margin-top: -5rem;
    margin-left: -3rem;
  }
}

@media screen and (max-width: 430px) {
  #header {
    background-position: -18rem;
  }

  .intro {
    background-color: rgba(85, 85, 85, 0.5);
    border: 1px solid white;
    padding: .5rem;
  }

  .header-container h1 {
    font-size: 3rem;
  }

  .header-container h4 {
    font-size: 1.5rem;
  }

  #code-gif {
    display: none;
  }

  .social-media ul {
    margin-top: 26rem;
    margin-left: 2rem;
    flex-direction: row;
  }

  .social-media ul li a .fab,
  .social-media ul li a .fa-brands {
    font-size: 4rem;
  }

  .social-media ul li a::before {
    font-size: 4rem;
  }
}

@media screen and (max-width: 414px) {
  #header {
    background-position: -21rem;
  }

  .header-container h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 393px) {
  .social-media ul {
    margin-top: 18rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 390px) {
  #header {
    min-height: 110vh;
  }

  .social-media ul {
    margin-left: auto;
  }
}

@media screen and (max-width: 375px) {
  .header-container h1 {
    font-size: 2rem;
  }

  .hello {
    font-size: 1rem;
  }

  .social-media ul {
    margin-top: 26rem;
    margin-left: 0rem;
  }

  .social-media ul li a .fab,
  .social-media ul li a .fa-brands {
    font-size: 4rem;
  }

  .social-media ul li a::before {
    font-size: 4rem;
  }
}

@media screen and (max-width: 360px) {
  #header {
    background-position: -19rem;
  }

  .social-media ul {
    margin-top: 28rem;
  }
}

@media screen and (max-width: 320px) {
  #header {
    background-position: -28rem;
  }

  .social-media ul {
    margin-top: 30rem;
    margin-left: -1rem;
  }
}

@media screen and (max-width: 280px) {
  #header {
    background-position: -21rem;
  }

  .social-media ul {
    margin-top: 20rem;
    margin-left: -1rem;
  }

  .social-media ul li a .fab,
  .social-media ul li a .fa-brands {
    font-size: 3rem;
  }

  .social-media ul li a::before {
    font-size: 3rem;
  }
}