:root {
    --advertising-header: hotpink;
    --advertising-background: rgb(16, 18, 18);
}

#advertising-projects {
    background-color: var(--advertising-background);
}

.advertising-projects-title {
    border: solid 2px white;
    background-color: transparent;
    text-transform: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Allerta Stencil', sans-serif;
}

.figma-title,
.canva-title {
    border-bottom: 1px solid white;
}

.tiktok-figma {
    max-width: 100%;
    height: auto;
}

.advertising-container {
    margin: auto;
    max-width: 1700px;
    padding: 0 1rem;
    padding-bottom: 5rem;
}

.advertising-projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    gap: 10px;
}

.equistaff,
.danahall,
.kentucky-tack,
.virginia-a-circuit,
.sarah-wildasin,
.creekside-eventing,
.cowan-ranch,
.barn-staff-williston,
.agri-associates,
.ford-field,
.barn-manager-boston,
.diasti-stables,
.scva,
.exercise-rider,
.graphic-designer,
.groom-tryon,
.hunter-jumper-washington,
.minitube,
.horse-show-organizer {
    max-width: 800px;
    height: auto;
}

.ps-ad {
    width: 800px;
    height: 670px;
}

.candidate-flyer,
.client-flyer {
    width: 800px;
    height: auto;
}

@media screen and (max-width: 1440px) {
    .advertising-container {
        margin: auto;
    }

    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 100%;
        height: auto;
    }

    .ps-ad {
        max-width: 100%;
        height: 100%;
    }

    .candidate-flyer,
    .client-flyer {
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 1280px) {
    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 600px;
        height: auto;
    }

    .ps-ad {
        width: 600px;
        height: 500px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 600px;
        height: auto;
    }

}

@media screen and (max-width: 1024px) {
    .advertising-container {
        margin: auto;
        max-width: 950px;
    }
    
    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 450px;
        height: auto;
    }

    .ps-ad {
        width: 450px;
        height: 376px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 450px;
        height: auto;
    }

}

@media screen and (max-width: 912px) {
    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 400px;
        height: auto;
    }

    .ps-ad {
        width: 400px;
        height: 340px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 400px;
        height: auto;
    }

}

@media screen and (max-width: 820px) {
    .advertising-projects {
        display: flex;
        flex-direction: column;
    }

    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 800px;
        height: auto;
    }

    .ps-ad {
        width: 700px;
        height: 600px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 700px;
        height: auto;
    }

}

@media screen and (max-width: 768px) {
    .advertising-projects {
        display: flex;
        flex-direction: column;
    }

    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 800px;
        height: auto;
    }

    .ps-ad {
        width: 750px;
        height: 600px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 750px;
        height: auto;
    }

}

@media screen and (max-width: 540px) {
    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 350px;
        height: auto;
    }

    .ps-ad {
        width: 350px;
        height: 300px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 350px;
        height: auto;
    }

}

@media screen and (max-width: 430px) {
    .advertising-projects {
        align-items: center;
    }

    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 350px;
        height: auto;
    }

    .ps-ad {
        width: 350px;
        height: 300px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 350px;
        height: auto;
    }
}

@media screen and (max-width: 320px) {
    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 300px;
        height: auto;
    }

    .ps-ad {
        width: 300px;
        height: 250px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 300px;
        height: auto;
    }

}

@media screen and (max-width: 280px) {
    .equistaff,
    .danahall,
    .kentucky-tack,
    .virginia-a-circuit,
    .sarah-wildasin,
    .creekside-eventing,
    .cowan-ranch,
    .barn-staff-williston,
    .agri-associates,
    .ford-field,
    .barn-manager-boston,
    .diasti-stables,
    .scva,
    .exercise-rider,
    .graphic-designer,
    .groom-tryon,
    .hunter-jumper-washington,
    .minitube,
    .horse-show-organizer {
        max-width: 250px;
        height: auto;
    }

    .ps-ad {
        width: 250px;
        height: 200px;
    }

    .candidate-flyer,
    .client-flyer {
        width: 250px;
        height: auto;
    }

}