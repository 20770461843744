:root {
  --nav-background: hotpink;
  --nav-text: white;
  --nav-drop: #313131;
  --nav-bg: black;
}

.nav-link {
  color: var(--nav-text) !important;
}

.navbar {
  font-family: 'Playpen Sans', cursive;
  background-color: var(--nav-bg);
}

.navbar a {
  font-size: 25px;
  padding: 14px 16px;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: center;
}

.logo-col {
  margin: auto;
}

.dropdown-menu {
  background-color: var(--nav-drop);
}

.dropdown-item {
  color: var(--nav-text);
}

.dropdown-item:hover {
  color: var(--nav-background);
  background-color: var(--nav-drop);
}

.active {
  color: var(--nav-background) !important;
}

.navbar-toggler {
  display: block;
  margin: auto;
  background-color: var(--nav-text);
}