:root {
  --services-background: pink;
  --header-background: hotpink;
  --hover: orchid;
}

#web-dev-services {
  background: rgb(47, 47, 47);
  min-height: 100vh;
}

#web-dev-services h1 {
  text-align: center;
  font-size: 4rem;
  font-family: 'Allerta Stencil', sans-serif;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-transform: none;
  border: solid 2px white;
  background-color: transparent;
}

.web-dev-services-container {
  min-width: 100vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.web-dev-service {
  background: var(--services-background);
  border-radius: 2rem;
  border: 1px solid var(--header-background);
}

.web-dev-service-header {
  background: var(--header-background);
  border-radius: 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.web-dev-service-header h2 {
  font-size: 2rem;
  text-align: center;
  font-family: "Preahvihear", sans-serif;
}

.web-dev-service-name li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.web-dev-service-name-icon {
  margin-top: .4rem;
}

.web-dev-service-name p {
  font-size: 1.2rem;
  font-family: "Preahvihear", sans-serif;
}

@media screen and (max-width: 1024px) {
  .web-dev-services-container {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  #web-dev-services h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .web-dev-services-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 430px) {
  #web-dev-services h1 {
    font-size: 2rem;
  }
}