:root {
    --web-design-background: rgb(16, 18, 18);
}

#print-design-projects {
    background-color: var(--web-design-background);
    height: 100%;
}

.print-design-container {
    padding-bottom: 5rem;
}

.print-design-projects-title {
    border: solid 2px white;
    background-color: transparent;
    text-transform: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: 'Allerta Stencil', sans-serif;
}

.print-projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    gap: 2rem;
    margin-top: 2rem;
}

.nordic-card,
.equistaff-flyer,
.equistaff-brochure,
.mary-card {
    width: 100%;
    height: 40rem;
}

@media screen and (max-width: 1024px) {
    .print-design-container {
        margin: auto;
        max-width: 950px;
    }

    .nordic-card,
    .equistaff-flyer,
    .equistaff-brochure,
    .mary-card {
        width: 100%;
        height: 30rem;
    }
}

@media screen and (max-width: 820px) {
    .nordic-card,
    .equistaff-flyer,
    .equistaff-brochure,
    .mary-card {
        width: 100%;
        height: 25rem;
    }
}

@media screen and (max-width: 768px) {
    .nordic-card,
    .equistaff-flyer,
    .equistaff-brochure,
    .mary-card {
        width: 100%;
        height: 25rem;
    }
}

@media screen and (max-width: 540px) {
    .nordic-card,
    .equistaff-flyer,
    .equistaff-brochure,
    .mary-card {
        width: 100%;
        height: 17rem;
    }
}

@media screen and (max-width: 430px) {
    .print-projects {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(100px, auto);
        gap: 2rem;
        margin-top: 2rem;
    }

    .nordic-card,
    .equistaff-flyer,
    .equistaff-brochure,
    .mary-card {
        width: 100%;
        height: 25rem;
    }
}

@media screen and (max-width: 375px) {
    .nordic-card,
    .equistaff-flyer,
    .equistaff-brochure,
    .mary-card {
        width: 100%;
        height: 20rem;
    }
}