:root {
  --services-background: pink;
  --header-background: hotpink;
  --hover: orchid;
  --black: black;
}

#graphic-design-services {
  min-height: 100vh;
  background-color: var(--black);
}

.graphic-design-services-title {
  text-align: center;
  font-size: 4rem;
  font-family: 'Allerta Stencil', sans-serif;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: solid 2px white;
  background-color: transparent;
  text-transform: none;
}

.graphic-design-services-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
}

.graphic-design-services-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
}

.graphic-design-service {
  background: var(--services-background);
  border-radius: 2rem;
  width: 23rem;
  height: 27rem;
  margin: auto;
}

.graphic-design-service-header {
  background: var(--header-background);
  border-radius: 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.graphic-design-service-header h2 {
  font-size: 2rem;
  text-align: center;
  font-family: "Preahvihear", sans-serif;
}

.graphic-design-service-name li {
  display: flex;
  margin-bottom: 0.5rem;
}

.gd-price {
  background-color: transparent;
  font-size: 2.5rem;
  color: var(--black);
  text-transform: none;
}

.gd-desc {
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 1024px) {
  .graphic-design-service {
    width: 25rem;
  }
}

@media screen and (max-width: 820px) {
  .graphic-design-services-title {
    font-size: 3rem;
  }

  .graphic-design-service {
    width: 40rem;
  }
}

@media screen and (max-width: 600px) {
  .graphic-design-services-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 430px) {
  .graphic-design-services-title {
    font-size: 2rem;
  }

  #graphic-design-services {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .graphic-design-services-title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 325px) {
  .graphic-design-service-header h2 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 280px) {
  .gd-price {
    font-size: 2rem;
  }
  
  .gd-desc {
    font-size: 1rem;
  }
}